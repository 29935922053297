$primary-color: #aafcfc;
$secondary-color: #1a2023;
$tile-background-color-transparent: #232c3166;
$tile-background-color: #181d20;
$dark-accent-color: #242c31;
$pure-white: #d6eeee;
$htmlTagColor: #4a4b4d;
$placeholder-color: #545454;
$divider-line-color: #545454;
$background-color: #121315;
$danger-color: #bb2124;
$success-color: #22bb33;

$accent-text-color: #00dede;
$shadow-text-color: #00dede;

//sidebar
$sidebar-text-color: #d6eeee;

//menu
$menu-background-color: #121315;
$menu-hover-text-color: #00dede;
