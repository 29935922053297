@import './app/styles/colors';

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Protests";
  src: url("./assets/fonts/Protest_Revolution/ProtestRevolution-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Syne";
  src: url("./assets/fonts/Syne/Syne-VariableFont_wght.ttf") format("truetype");
}

html {
  overflow-x: hidden;
  font-family: "Manrope", sans-serif;
}

body {
  color: $pure-white;
  overflow-x: hidden;
  background-color: $background-color;
}
